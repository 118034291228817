import React from 'react'
import PropTypes from 'prop-types'
import { Router } from '@reach/router'
import Amplify, { Auth } from 'aws-amplify'
import Login from './Login'
import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignUp,
  VerifyContact,
  withAuthenticator } from 'aws-amplify-react'

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
})
Auth.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
})

const PrivateRoute = ({ component: Component, fsOnly, ...rest }) => {
  return (
    <Router>
    <Component {...rest} />
    </Router>
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default withAuthenticator(PrivateRoute, false, [
  <Login/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <SignUp/>,
  <ConfirmSignUp/>,
  <ForgotPassword/>,
  <RequireNewPassword />
])
