import React from 'react'
import ProfileEdit from '../components/ProfileEdit'
import PrivateRoute from '../components/PrivateRoute'
import { isBrowser } from '../utils/misc'
const App = ({ data }) => {
  if (isBrowser) {
    return (
      <PrivateRoute
        path="/profile-edit"
        component={ProfileEdit}
        fsOnly="false"
        data={data}
      />
    )
  } else {
    return null
  }
}

export default App
