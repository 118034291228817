import React from 'react'
import View from './View'
import Layout from './Layout'
import { getCurrentUser,
  changePassword,
} from '../utils/auth'
import {
  getUserInfo,
  updateProfileImage,
  getProfileImage,
  updateProfile
} from '../utils/user'
import PasswordForm from './Form/change-password'
import ProfileEditForm from './Form/profile-attributes'
import Tabs from './Tabs'
import './Tabs/tabs.module.css'
import zipcodes from 'zipcodes'
import Dropzone from 'react-dropzone'
import WaitLoader from './WaitLoader'
import * as styles from './Profile/profile-edit.module.css'
import blankProfile from '../../static/img/blank_profile.jpg'

class ProfileEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previousPassword: ``,
      newPassword: ``,
      userData: {},
      errorMessage: '',
      successMessage: '',
      profileErrorMessage: '',
      profileSuccessMessage: '',
      profileImage: blankProfile,
      uploadingImage: false,
      profileImageUpload: null,
      userAttributes: {
        email: '',
        name: {
          first: '',
          last: ''
        },
        company: '',
        jobTitle:'',
        phone:'',
        city:'',
        country:'',
        postalCode:'',
        s3Image:''
      }
    }
  }

  componentDidMount() {
    var userInfo = getCurrentUser()
    this.setState({
      email: userInfo.email
    })
    this.getUserData()
  }

  handleProfileSubmit(event){
    event.preventDefault()
    updateProfile(this.state.userAttributes, function(err, data){
      console.log(err)
      console.log(data)
      if(err){
        this.setState({
          profileErrorMessage: err.message,
          profileSuccessMessage: ''
        })
      }else{
        this.setState({
          profileErrorMessage: '',
          profileSuccessMessage: data
        })
      }
    });
  }


  getUserData(successMessage = '') {
    getUserInfo()
    .then(function(userInfo){
      let profileImage = getProfileImage()
      if(!profileImage){
        profileImage = blankProfile
      }
      this.setState({
        userAttributes: userInfo,
        profileImage: profileImage,
        profileImageUpload: null,
        uploadingImage: false,
        successMessage: successMessage
      })
    }.bind(this))
    .catch(function(err){
      this.setState({
        errorMessage: err.messagee
      })
    }.bind(this))
  }

  handleSubmit(event) {
    event.preventDefault()
    changePassword(
      this.state.previousPassword,
      this.state.newPassword,
      function(err, data) {
        if (err) {
          this.setState({
            errorMessage: err.message,
            successMessage: '',
          })
        } else {
          this.setState({
            errorMessage: '',
            successMessage: 'Password updated succesfully.',
          })
        }
      }.bind(this)
    )
  }

  handleProfileUpdate(profile) {
    console.log(profile)
    this.setState({
      userAttributes: profile
    }, function(){
      updateProfile(this.state.userAttributes)
      .then(function(data){
        this.setState({
          profileErrorMessage: '',
          profileSuccessMessage: 'Profile Updated'
        })
      }.bind(this))
      .catch(function(err){
        this.setState({
          profileErrorMessage: err.message,
          profileSuccessMessage: ''
        })
      }.bind(this))
    })
  }

  handlePhoneUpdate(event) {
    let attributes = {...this.state.userAttributes}
    attributes.phone = event
    this.setState({
      userAttributes: attributes
    })
  }

  handleCountryUpdate(event) {
    let attributes = {...this.state.userAttributes}
    attributes.country = event
    this.setState({
      userAttributes: attributes
    })
  }

  handlePostCodeUpdate(event) {
    let attributes = {...this.state.userAttributes}
    let ziplookup = zipcodes.lookup(event.target.value)
    if(ziplookup){
      attributes.city = ziplookup.city
      switch (ziplookup.country){
        case 'US':
          attributes.country = 'United States'
          break
        default:
          attributes.country = ziplookup.country
          break
      }
    }
    attributes.postal_code = event.target.value
    this.setState({
      userAttributes: attributes
    })
  }

  replaceProfileImage(image){
    if(image.length){
      this.toBase64(image[0], function(base64Image){
        this.setState({
          profileImageUpload: image[0],
          profileImage: base64Image,
          successMessage: ''
        })
      }.bind(this))
    }
  }

  uploadProfileImage(image){
    this.setState({
      uploadingImage: true
    }, function(){
      updateProfileImage(image)
      .then(function(data){
        console.log(data)
        this.getUserData('Profile Image Updated')
      }.bind(this))
      .catch(function(err){
        console.log(err)
      })
    }.bind(this))
  }

  toBase64(blob, callback){
    var reader = new FileReader();
    reader.readAsDataURL(blob)
    reader.onloadend = function() {
        var base64data = reader.result
        callback(base64data)
    }
  }

  render() {
    let profileImage = null
    if(this.state.profileImage){
      profileImage = <img src={this.state.profileImage} alt="Profile" />
    }
    let profileImageUploadButton = null
    if(this.state.profileImageUpload){
      profileImageUploadButton = <form><button
        className={styles['profileImageSubmit']}
        onClick={(e) => {
          e.preventDefault();
          this.uploadProfileImage(this.state.profileImageUpload)}}>
        Upload Image</button></form>
    }
    let imageUploading = null
    if(this.state.uploadingImage){
      imageUploading = <div style={{
        position:'absolute',
        top:'0px',
        left:'0px',
        width:'100%',
        height:'100%',
        display:'flex',
        justifyContent: 'center',
        alignItems:'center',
        backgroundColor: 'rgba(0,0,0,0.6)'}}>
      <WaitLoader message="Uploading Image..." />
      </div>
    }
    return (
      <Layout>
      <View title="PROFILE EDIT">
        <Tabs>
           <div label="Account">
            <div className="row">
            <div className="col-lg-6 col-xs-12">
            <h3>Profile Image</h3>
              <div style={{position: 'relative'}}>
                {imageUploading}
                {profileImage}
              </div>
              <Dropzone
              onDrop={acceptedFiles => this.replaceProfileImage(acceptedFiles)}
              accept={['image/jpeg','image/png']}
              multiple={false}>
                {({getRootProps, getInputProps}) => (
                  <section className={styles['profileImageDropzone']}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <i className="las la-image"></i>
                      <p style={{textAlign: 'center'}}>
                      Drag and drop here, or click to replace profile image.
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              {profileImageUploadButton}
            </div>
            <div className="col-lg-6 col-xs-12">
             <PasswordForm
               username={this.state.email}
               handleUpdate={e => this.handleUpdate(e)}
               handleSubmit={e => this.handleSubmit(e)}
             />
             <label className="error">{this.state.errorMessage}</label>
             <label>{this.state.successMessage}</label>
            </div>
            </div>
           </div>
           <div label="Contact Info">
             <ProfileEditForm
               profile={this.state.userAttributes}
               handleUpdate={e => this.handleProfileUpdate(e)}
               handleSubmit={e => this.handleProfileSubmit(e)}
               handlePhoneUpdate={e => this.handlePhoneUpdate(e)}
               handleCountryUpdate={e => this.handleCountryUpdate(e)}
               handlePostCodeUpdate={e => this.handlePostCodeUpdate(e)}
             />
             <label className="error">{this.state.profileErrorMessage}</label>
             <label>{this.state.profileSuccessMessage}</label>
           </div>
         </Tabs>


      </View>
      </Layout>
    )
  }
}

export default ProfileEdit
