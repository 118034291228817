import React from 'react'
import * as styles from './form.module.css'
import { Form, Field } from 'react-final-form'
import AutoSave from './AutoSave'
import { CountryDropdown } from 'react-country-region-selector'

const CountryAdapter = ({ input }) => (
  <CountryDropdown {...input}
    priorityOptions={["US"]}
    className={styles['form__select']}
  />
)

class ProfileAttributesForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  save = values => {
    if(!this.props.profile.name){
      this.props.profile.name = {
        first: '',
        last: ''
      }
    }
    Object.keys(values).forEach(key => {
      switch (key){
        case 'firstName':
          this.props.profile.name.first = values[key]
          break
        case 'lastName':
          this.props.profile.name.last = values[key]
          break
        default:
          this.props.profile[key] = values[key]
      }
    })
  }

  submit = async (values) => {
    await this.props.handleUpdate(this.props.profile)
  }

  render(){
    var initialValues = {
      email: this.props.profile.email,
      firstName: this.props.profile.name ? this.props.profile.name.first : '',
      lastName: this.props.profile.name ? this.props.profile.name.last : '',
      company: this.props.profile.company,
      jobTitle: this.props.profile.jobTitle,
      phone: this.props.profile.phone,
      country: this.props.profile.country,
      city: this.props.profile.city,
      postal_code: this.props.profile.postal_code
    }
    let FSFields = ''
    if(this.props.profile.email.toLowerCase().includes('@forms-surfaces.com')){
      FSFields = <div className="row">
      <div className="col-lg-6 col-sm-6 col-xs-12">
      <div>
        <label htmlFor="websiteDomain" className={styles['form__label']}>
          Website Domain:
          <Field
            className={styles['form__select']}
            component='select'
            id='websiteDomain'
            name='fsOnly.websiteDomain'>
            <option value="https://www.forms-surfaces.com">www.forms-surfaces.com</option>
            <option value="https://www.forms-surfaces.co.in">www.forms-surfaces.co.in</option>
          </Field>
        </label>
      </div>
      </div>
      </div>
      let websiteDomain = 'https://www.forms-surfaces.com'
      if(this.props.profile.fsOnly){
        websiteDomain = this.props.profile.fsOnly.websiteDomain
      }
      initialValues.fsOnly = {
        websiteDomain: websiteDomain
      }
    }
    return(
      <div className={styles.form}>
      <Form
        onSubmit={this.submit}
        initialValues={initialValues}
        subscription={{} /* No need to subscribe to anything */}
      >
      {( { handleSubmit } ) => (
        <form onSubmit={handleSubmit}>
          <AutoSave debounce={10} save={this.save} />
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div>
                <label
                  className={styles['form__label']}
                  htmlFor='email'>
                  Email:
                <Field
                  className={styles['form__input']}
                  readOnly
                  component='input'
                  id="email"
                  name="email"/>
               </label>
              </div>
              <div>
                <label
                  className={styles['form__label']}
                  htmlFor='firstName'>
                  First Name:
                <Field
                  className={styles['form__input']}
                  component='input'
                  id='firstName'
                  name='firstName'
                  />
                </label>
              </div>
              <div>
                <label
                  className={styles['form__label']}
                  htmlFor='lastName'>
                  Last Name:
                <Field
                  className={styles['form__input']}
                  component='input'
                  id='lastName'
                  name='lastName'/>
                </label>
              </div>
              <div>
                <label
                  className={styles['form__label']}
                  htmlFor='company'>
                  Company:
                <Field
                  className={styles['form__input']}
                  component='input'
                  id='company'
                  name='company'/>
                </label>
              </div>
              <div>
                <label
                  className={styles['form__label']}
                  htmlFor='jobTitle'>
                  Job Title:
                <Field
                  className={styles['form__select']}
                  component='select'
                  id='jobTitle'
                  name='jobTitle'>
                  <option value="Architect">Architect</option>
                  <option value="Interior Designer">Interior Designer</option>
                  <option value="Engineer - Struct/Mech/Civil">Engineer - Struct/Mech/Civil</option>
                  <option value="Graphic Designer">Graphic Designer</option>
                  <option value="Landscape Architect">Landscape Architect</option>
                  <option value="Project Manager">Project Manager</option>
                  <option value="Resource Librarian">Resource Librarian</option>
                  <option value="Spec Writer - CSI">Spec Writer - CSI</option>
                  <option value="Contractor">Contractor</option>
                  <option value="Construction Manager">Construction Manager</option>
                  <option value="Elevator Sales - Custom Cabs">Elevator Sales - Custom Cabs</option>
                  <option value="Estimator">Estimator</option>
                  <option value="Landscape Architect">Project Engineer</option>
                  <option value="Landscape Architect">Buyer</option>
                  <option value="Property Manager">Property Manager</option>
                  <option value="Developer">Developer</option>
                  <option value="General Manager">General Manager</option>
                  <option value="Owner">Owner</option>
                  <option value="Facilities Manager">Facilities Manager</option>
                  <option value="Operations Manager">Operations Manager</option>
                  <option value="Recycle Coord/Env Services">Recycle Coord/Env Services</option>
                  <option value="Consultant - Misc">Consultant - Misc</option>
                  <option value="Elevator Consultant">Elevator Consultant</option>
                  <option value="Sales Manager">Sales Manager</option>
                  <option value="Other">Other</option>
                </Field>
                </label>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div>
                <label htmlFor="country" className={styles['form__label']}>
                  Country:
                  <Field
                    component={CountryAdapter}
                    id="country"
                    name="country"
                  />
                </label>
              </div>
              <div>
                <label
                  htmlFor="city"
                  className={styles['form__label']}>
                  City:
                  <Field
                    id="city"
                    className={styles['form__input']}
                    component='input'
                    name="city"
                  />
                </label>
              </div>
              <div>
                <label
                  htmlFor="postal_code"
                  className={styles['form__label']}>
                  Zip/Postal Code:
                  <Field
                    id="postal_code"
                    component='input'
                    className={styles['form__input']}
                    name="postal_code"
                  />
                </label>
              </div>
              <div>
                <label htmlFor="phoneNumber" className={styles['form__label']}>
                  Phone:
                  <Field
                    component='input'
                    id="phoneNumber"
                    className={styles['form__input']}
                    name="phone"
                  />
                </label>
              </div>
            </div>
          </div>
          {FSFields}
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <input className={styles['form__button']} type="submit" value="Update" />
            </div>
          </div>
        </form>
      )}
      </Form>
      </div>
    )
  }
}

export default ProfileAttributesForm
